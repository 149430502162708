/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-lhqlt2 css-1vn4j6v --style2 --full pb--60 pt--60" name={"n68usq728wa"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f396f15b178e44c182c1004870966745_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f396f15b178e44c182c1004870966745_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f396f15b178e44c182c1004870966745_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f396f15b178e44c182c1004870966745_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f396f15b178e44c182c1004870966745_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f396f15b178e44c182c1004870966745_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f396f15b178e44c182c1004870966745_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/f396f15b178e44c182c1004870966745_s=3000x_.jpg);
    }
  
background-position: 50% 74%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"O nás"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"ae0gc434slt"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box text-box--left fs--20" style={{"maxWidth":1360}} content={"<span style=\"color: rgb(0, 0, 0);\">Oliver's Coffee Cup je útulná kavárna s výběrovou kávou, domácími dezerty a vždy milou obsluhou. První pobočku jsme otevřeli na Václavském náměstí v Domě Módy, naši druhou pobočku najdete v samém srdci Karlína, a to ve Futurama Business Park.\n<br><br>Ptáte se proč zrovna Oliver? Oba synové našich zakladatelů nesou toto jméno. A naši kavárnu bereme jako člena rodiny.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"qj1rsqwtq6q"} style={{"backgroundColor":"rgba(112,156,159,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(255, 255, 255);\">Naše pobočky</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":691}} srcSet={"https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\">Václavské náměstí</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(255, 255, 255);\">Najdete nás v Domě módy, a to v 1. patře s výhledem na Václavské náměstí a Národní muzeum. Vaše chuťové buňky uspokojíme kvalitní výběrovou kávou s domácími dezerty nebo osvěžující limonádou.\n<br><br>Otevřeno máme po celý týden a to od pondělí do pátku v čase 8:30- 20:30, o víkendu 9:00-20:00.</span><br>"}>
              </Text>

              <Image className="--center" src={"https://cdn.swbpg.com/t/18947/30b9958c489a4512b95caf819b05e94d_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":63}} srcSet={"https://cdn.swbpg.com/t/18947/30b9958c489a4512b95caf819b05e94d_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/30b9958c489a4512b95caf819b05e94d_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/30b9958c489a4512b95caf819b05e94d_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box text-box--center mt--0" content={"<span style=\"color: rgb(255, 255, 255);\">1 minuta chůze od metra A/C Muzeum</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\">Karlín</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(255, 255, 255);\">Sídlíme i v Karlíně, konkrétně ve Futurama Business Park. Vychutnejte si skvělou kávu u vaší pracovní schůzky nebo se zastavte třeba na plněné croissanty, které pro vás připravujeme čerstvé každý den.\n<br><br>Otevřeno máme od pondělí do pátku, v čase 8:00–18:00.</span><br>"}>
              </Text>

              <Image className="--center" src={"https://cdn.swbpg.com/t/18947/30b9958c489a4512b95caf819b05e94d_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":59}} srcSet={"https://cdn.swbpg.com/t/18947/30b9958c489a4512b95caf819b05e94d_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/30b9958c489a4512b95caf819b05e94d_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/30b9958c489a4512b95caf819b05e94d_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box text-box--center mt--0" style={{"maxWidth":326}} content={"<span style=\"color: rgb(255, 255, 255);\">2 minuty chůze od metra B Invalidovna</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":687}}>
              
              <Image src={"https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":693}} srcSet={"https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/18947/c21f75dbe2d941f19e7ffb38133abb2f_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--20 pb--60 pl--20 pr--20 pt--60">
          
          <ColumnWrap className="column__flex el--4 pl--0 pr--0 flex--top" columns={"4"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <Image className="--justify pl--30" alt={""} src={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":144}} srcSet={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">E-MAIL</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\">info@oliverscoffeecup.cz</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">TELEFON</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\">+420 234 101 138</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">SLEDUJTE NÁS</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\"><a href=\"https://www.facebook.com/oliverscoffeecup.cz\" target=\"_blank\">Facebook<br></a><a href=\"https://www.instagram.com/oliverscoffeecup/\" target=\"_blank\">Instagram</a><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}